// zh.js
export default {
    "产品":'产品',
    "合作":'合作',
    "关于我们":"关于我们",
    "同业合作":"同业合作",
    "地区合伙人":"地区合伙人",
    "关于RIAT":"关于RIAT",
    "加入RIAT":"加入RIAT",
    "全球收款账户":"全球收款账户",
    "RIAT实体卡":"RIAT实体卡",
    "企业虚拟卡":"企业虚拟卡",
    "RIAT CARD APP":"RIAT CARD APP",
    "登录":"登录",
    "注册":"注册",
    "版权所有":"© 版权所有",
    "业务合作联系人":"业务合作联系人",
    "隐私政策":"隐私政策",
    "服务条款":"服务条款",
    "企业虚拟账户":"企业虚拟账户",
    "RIAT下载":"RIAT CARD APP下载",
    "你好我们是":"你好，我们是",
    "欢迎加入RIAT地区":"欢迎加入RIAT地区/国家合伙人计划，共同助推全球的数字经济发展",
    "联系我们":"联系我们",
    "RIAT在深圳":"RIAT在深圳、中国香港、马来西亚、新加坡、加拿大、美国都开设有办公室，我们很希望见到您加入我们的团队",
    "拥抱世界看见未来":"拥抱世界，看见未来",
    "如您也是跨境相关行业":"如您也是跨境相关行业，我们希望跟您多多交流学习，望不吝赐教",
    "如不嫌弃":"如不嫌弃，",
    "请放轻松随时联系我们":"请放轻松随时联系我们",
    "我们会以最快的速度和最大的热情回复您":"我们会以最快的速度和最大的热情回复您",
    "请填写您的信息":"请填写您的信息，方便我们会第一时间回复您",
    "输入姓名":"输入姓名 *",
    "输入邮箱":"输入邮箱 *",
    "输入电话区号":"输入电话区号 *",
    "输入电话号码":"输入电话号码 *",
    "填写您的留言":"填写您的留言",
    "发送至邮箱":"发送至邮箱",
    "RIAT地区合伙人计划":"RIAT地区合伙人计划",
    "国家合伙人计划":"欢迎加入RIAT地区/国家合伙人计划，共同助推全球的数字经济发展",
    "为让合作更加顺利":"为让合作更加顺利，我们需要您具备以下条件",
    "具有合法合规企业资质":"具有合法合规企业资质",
    "拥有满足合规要求的境外企业主体":"拥有满足合规要求的境外企业主体，有正常使用的银行账户，以及正常合规需要的资料",
    "具有行业背景":"具有行业背景",
    "对跨境行业有足够经验":"对跨境行业有足够经验、有成熟的经营团队，并目前处于运营的状态",
    "具有一定客户资源":"具有一定客户资源",
    "拥有预期合作地区":"拥有预期合作地区/国家一定的企业级资源或政府协会资源",
    "如有问题请填写您的邮箱地址我们会第一时间与您联系":"如有问题请填写您的邮箱地址我们会第一时间与您联系",
    "发送":"发送",
    "输入邮箱1":"输入邮箱",
    "任何时候":"任何时候，都可以全面管理你的卡片和资金",
    "随时随地":"随时随地",
    "移动端APP提供实时充值":"移动端APP提供实时充值、查询余额、收取交易通知等等",
    "一张卡":"一张卡，让我们走遍每个角落",
    "全球":"全球、全币种、全场景，我们为您扫清走遍全球的障碍",
    "低门槛":"低门槛，高额度",
    "高额度":"高额度，无拘束",
    "最高日均":"最高日均100,000USD,月均3,000,000USD",
    "快速下卡":"快速下卡",
    "线上开卡":"线上开卡，快速审核，最快3天卡片快递到手",
    "全球ATM机器支持":"全球ATM机器支持",
    "快捷":"快捷、安全、便利",
    "卡组合作":" 卡组合作，安全放心",
    "与MASTERCARD联名发卡":"与MASTERCARD联名发卡，享受MASTERCARD成熟安全的结算网络",
    "交易的纵横":"交易的纵横，我们面面俱到",
    "依托MASTERCARD的全球网络":" 依托MASTERCARD的全球网络，您能支付到全球任何一个角落",
    "账目清晰":" 账目清晰，费用透明",
    "实时账单推送":"实时账单推送，清晰记录每笔资金使用明细和去向",
    "现在就获取您的RIAT实体卡":"现在就获取您的RIAT实体卡",
    "仅用一张":"仅用一张，走遍世界每个圈",
    "获取RIAT实体卡":"获取RIAT实体卡",
    "RIAT创新跨境金融服务方案":"RIAT创新跨境金融服务方案",
    "RIAT同时整合全球首款":"RIAT同时整合全球收款，多种换汇、全球付款、企业卡等金融产品，助力跨境企业全球经营",
    "全球收款账户立即解锁安全":"全球收款账户，立即解锁安全、快速高效的全球收付汇功能",
    "无需如实体银行的繁琐手续和资料":"无需如实体银行的繁琐手续和资料，最快10分钟内完成账户开设",
    "低成本完成全球资金收付":"低成本完成全球资金收付，节约企业成本，助力企业增长",
    "高效收付网络系统":"高效收付网络系统，付款收款最快T+0完成",
    "拥有多国支付牌照":"拥有多国支付牌照，接受多部门监管，安全合规有保障",
    "RIAT企业虚拟卡":"RIAT企业虚拟卡",
    "支持全球币种线上支持场景":"支持全球币种线上支持场景，解决企业全球付款需求",
    "一键完成开卡":"一键完成开卡，即开即用",
    "无开卡数量限制":"无开卡数量限制，满足不同行业需求",
    "支持全球币种线上场景":"支持全球币种线上场景",
    "提供API接口":"提供API接口，让使用更高效",
    "支持全球币种线下交易":"支持全球币种线下交易",
    "支持全球ATM机器取现金提款":"支持全球ATM机器取现金提款，从此告别跨境先进烦恼",
    "实时账单推送消费一目了然":"实时账单推送，消费一目了然",
    "超低费率":"超低费率，透明汇率，为您节省更多",
    "RIAT企业实体卡":"RIAT企业实体卡",
    "用一张卡让商旅出行不在束缚":"用一张卡，让商旅出行不在束缚",
    "任何时候都可以全面管理你的卡片和资金":"任何时候，都可以全面管理你的卡片和资金",
    "海外付款":"海外付款，瞬间完成",
    "全景支持":"20+VISA和Mastercard 卡BIN线上消费全景支持",
    "一键开卡灵活使用":"一键开卡，灵活使用",
    "灵活的线上管理":"灵活的线上管理",
    "通过系统轻松实现卡片额度调整":"无卡的数量限制，通过系统轻松实现卡片额度调整、消费、账单查询、账单等功能",
    "BIN号丰富":"BIN号丰富",
    "拥有全球多地区多国家VISA":"拥有全球多地区多国家VISA、MASTER的BIN号，支持全球币种结算，支持VISA验证、支持3DS验证、支持VCC2验证",
    "全场景支持":"全场景支持",
    "店租":"店租（Amazon、Shopify、Shope等）、云服务、游戏、开发者应用、海淘购物等全景支付",
    "适合多种跨境业":"适合多种跨境业",
    "让跨境企业海外付款更高效":"让跨境企业海外付款更高效",
    "跨境电商":"跨境电商",
    "独立站运营费用":"独立站运营费用；亚马逊、速卖通等租金及广告费用；域名购买、服务器租赁、物流等费用。",
    "海淘购物":"海淘购物",
    "解决海外网站购买":"解决海外网站购买、海外游戏付款、软件订阅付款、开发者账号、境外电话卡WIFI流量等需求。",
    "海外广告":"海外广告",
    "轻松支付":"轻松支付Facebook、Google、YouTube、Tiktok、Instagram、Twitter等平台广告费",
    "其他海外业务":"其他海外业务",
    "海外服务器租赁":"海外服务器租赁、海外域名购买、企业海外极品、酒店预定、留学费用支持、留学宿舍费用支付等。",
    "全平台支持跨境无障碍":"全平台支持，跨境无障碍",
    "轻松支付广告投放":"轻松支付广告投放、电商租金、网络服务等费用",
    "完善的后台功能":" 完善的后台功能、API接口输出",
    "账号账单导出":" 账号账单导出",
    "卡片账单导出":"卡片账单导出",
    "余额手机":"余额手机/邮件推送",
    "实时交易风控":"实时交易风控",
    "即刻体验瞬间付款的新形态":"即刻体验瞬间付款的新形态",
    "更多卡BIN正在路上":"更多卡BIN正在路上",
    "立即体验":"立即体验",
    "请输入邮箱":"请输入邮箱！",
    "请输入正确的邮箱地址":"请输入正确的邮箱地址!",
    "请输入姓名":"请输入姓名!",
    "请输入电话区号":"请输入电话区号!",
    "请输入电话号码":"请输入电话号码!",
    "请输入正确的电话号码":"请输入正确的电话号码!",

    "通过RIAT全球收款账户":"通过RIAT全球收款账户，",
    "让您的业务触达全球":"让您的业务触达全球",
    "支持包括美元":"支持包括美元，英镑、日元、欧元、港币在内的15种币种全球收款服务",
    "低成本更高效":"低成本，更高效",
    "快速下户":"快速下户",
    "无徐传统银行的繁琐手续和资料":"无徐传统银行的繁琐手续和资料，最快10分钟内完成账户开设",
    "同名收付":"同名收付",
    "提供美国":"提供美国、香港、新加坡等地的银行同名账户，解决企业收付需同名的需求",
    "比传统银行节约超过":"比传统银行节约超过90%的使用成本，最快T+到账时效",
    "多币种多平台":"多币种，多平台",
    "多种主流币支持":"多种主流币支持",
    "包括美元":"包括美元、英镑、日元、加币、港币在内的12+币种",
    "支持绑定":"支持绑定Amazon、Paypal、Klarna等全球主流电商平台",
    "高效率":"低成本、高效率",
    "支持将收单进行资金结算到RIAT账户":"支持将收单进行资金结算到RIAT账户",
    "立刻创建您的RIAT账号全球收款账户":"立刻创建您的RIAT账号全球收款账户",
    "用一个账户让您业务触达全球":"用一个账户，让您业务触达全球",
    "立即创建":"立即创建",
    "立刻创建您的RIAT账号":"立刻创建您的RIAT账号",
    "感受创新科技金融带来的改变":"感受创新科技金融带来的改变",
    "收取交易":"收取交易",
    "查询余额":"查询余额",
    "实时充值":"实时充值",
    "语言":"语言",
   "已发送":"已发送",
   "我们会第一时间回复您":"我们会第一时间回复您"

}
