import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import product from '../views/product.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'product',
    component: product,
    meta:{
      title:"登录"
  }
   
  },
  {
    path: '/collectionAccount',
    name: 'collectionAccount',
    component: () => import(/* webpackChunkName: "about" */ '../views/collectionAccount.vue'),
   
  },
  {
    path: '/virtualCard',
    name: 'virtualCard',
    component: () => import(/* webpackChunkName: "about" */ '../views/virtualCard.vue'),
  }
  ,
  {
    path: '/tradeCooperation',
    name: 'tradeCooperation',
    component: () => import(/* webpackChunkName: "about" */ '../views/tradeCooperation.vue'),
  },
  {
    path: '/entityCard',
    name: 'entityCard',
    component: () => import(/* webpackChunkName: "about" */ '../views/entityCard.vue'),
  }
  ,
  {
    path: '/district',
    name: 'district',
    component: () => import(/* webpackChunkName: "about" */ '../views/district.vue'),
  }
  ,
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue'),
  },
  {
    path: '/asRegards',
    name: 'asRegards',
    component: () => import(/* webpackChunkName: "about" */ '../views/asRegards.vue'),
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue'),
  },
  {
    path: '/sendSuccessful',
    name: 'sendSuccessful',
    component: () => import(/* webpackChunkName: "about" */ '../views/sendSuccessful.vue'),
    
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
