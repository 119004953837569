// en.js
export default {
        "产品":'Products',
        "合作":'Partners',
        "关于我们":"About Us",
        "同业合作":"Collaboration",
        "地区合伙人":"Regional Partners",
        "关于RIAT":"About RIAT",
        "加入RIAT":"Join RIAT",
        "全球收款账户":"Global Payment Account Solutions by RIAT",
        "RIAT实体卡":"RIAT Physical Card",
        "企业虚拟卡":"Business Virtual Card",
        "RIAT CARD APP":"RIAT CARD APP",
        "登录":"Login",
        "注册":"Sign Up",
        "版权所有":"© Rights Reserved",
        "业务合作联系人":"Business Development Contact",
        "隐私政策":"Privacy Policy",
        "服务条款":"Terms and Conditions",
        "企业虚拟账户":"Business Virtual Account",
        "RIAT下载":"RIAT CARD APP Download",
        "你好我们是":"Hello, We are",
        "欢迎加入RIAT地区":"RIAT is the next generation FinTech service for global businesses, aiming to help clients boost their growth and profitability and their globalization effort. Usability and Security are our core strength. RIAT’s runs on fully in-house designed and developed platform that consists of comprehensive user dashboard which integrates risk control and regulatory compliance system.",//此处替代原有公司介绍部分
        "联系我们":"Contact Us",
        "RIAT在深圳":"Global Opportunities Await: RIAT is Everywhere You Are — from Shenzhen to Hong Kong, Kuala Lumpur to Canada, and across the US. Join our international team and be a part of something big. We're excited to welcome you aboard!",
        "拥抱世界看见未来":"Embrace the World, Eyes on the Future",
        "如您也是跨境相关行业":"Connect Over Coffee: Your Cross-Border Business, Our Expertise Embark on a conversation that could transform your cross-border business. Visit us and let's discuss your vision over a complimentary cup of coffee. Your next big opportunity is just a conversation away.",
        "如不嫌弃":"Do not hesitate",
        "请放轻松随时联系我们":"to get in touch with us anytime",
        "我们会以最快的速度和最大的热情回复您":"Prompt Response Guaranteed: We're Passionate About Supporting You! Your inquiries are important to us, and our dedicated team is eager to assist you with zeal. Expect a swift, passionate response because your success is our priority.",
        "请填写您的信息":"Stay Connected: Share Your Details for Personalized Contact We're ready to connect with you. Provide your information, and our team will reach out to you promptly. Your next step towards collaboration is just a few clicks away.",
        "输入姓名":"Enter your name *",
        "输入邮箱":"Enter your email *",
        "输入电话区号":"Enter country code *",
        "输入电话号码":"Enter phone number *",
        "填写您的留言":"Leave your message",
        "发送至邮箱":"Submit",
        "RIAT地区合伙人计划":"RIAT Regional Partnership Program",
        "国家合伙人计划":"Join Our Regional Partnership Program: Driving Digital Economy Innovation Together! Embrace the future with us and become a driving force in the digital economy. Engage, collaborate, and lead with our regional partnership program. Your journey to digital transformation starts here. ",
        "为让合作更加顺利":"Partner with Us: Unlock Possibilities Together. Let's explore the potential of a powerful partnership. To ensure alignment and compliance, we kindly ask that interested parties meet the following criteria:",
        "具有合法合规企业资质":"Legal and Financial Readiness",
        "拥有满足合规要求的境外企业主体":"You should have a recognized legal entity, complete with a functional bank account and necessary documentation.",
        "具有行业背景":" Industry Expertise",
        "对跨境行业有足够经验":" We look for partners with a robust background in cross-border business, supported by an operational team with hands-on experience.",
        "具有一定客户资源":"Resource Availability",
        "拥有预期合作地区":"Availability of substantial customer resources, including enterprise-level or governmental associations, within the regions or countries of intended collaboration.",
        "如有问题请填写您的邮箱地址我们会第一时间与您联系":"Simply leave us your email address and we'll ensure a prompt response to your queries. Your peace of mind is just an email away.",
        "发送":"Submit",
        "输入邮箱1":"Enter your email",
    "任何时候":"Effortless Card and Fund Management With our intuitive platform, take control of your cards and funds with comfort and ease - accessible 24/7 for your convenience.",
        "随时随地":"Always Connected, Always Empowered.",
        "移动端APP提供实时充值":"Experience real-time convenience with our Mobile App. Top-up funds instantly, check your balance on the fly, receive transaction notifications immediately, and much more - all within your reach, anytime, anywhere.",
        "一张卡":"Navigate Globally with Ease: The One Card Solution",
        "全球":"Experience the freedom to explore every corner of the world with our all-encompassing card. Worldwide acceptance, multi-currency support, suited for all scenarios — we're here to ensure your global journey is seamless and obstacle-free.",
        "低门槛":"Unlock Your Financial Potential: Effortless Access, Generous Limits",
        "高额度":"Unbounded Possibilities",
        "最高日均":"Enjoy high ceilings with our generous limits—up to $100,000 daily and $3,000,000 monthly, without constraints.",
        "快速下卡":"Swift Approval Process",
        "线上开卡":"Apply online and experience our rapid review. Your card is just an express delivery away, arriving in as little as 3 days.",
        "全球ATM机器支持":"Worldwide ATM Access: Withdraw and transact globally with our comprehensive ATM support, ready wherever you are.",
        "快捷":" Seamless Spending with RIAT Physical Card: Fast, Secure, Convenient",
        "卡组合作":"Trusted Partnership",
        "与MASTERCARD联名发卡":"Co-branded with Mastercard for a secure and dependable experience. Tap into the sophisticated and safe settlement network Mastercard is known for.",
        "交易的纵横":"Universal Payment Acceptance",
        "依托MASTERCARD的全球网络":"With Mastercard's worldwide network, your transactions are welcomed at millions of locations across the globe.",
        "账目清晰":"Transparent Tracking",
        "实时账单推送":"Keep your finances in clear view with real-time payment alerts and detailed records for every transaction. We prioritize transparency with straightforward account statements and fees.",
        "现在就获取您的RIAT实体卡":"Get Your RIAT Card Now!",
        "仅用一张":"One card, every corner, no limits.",
        "获取RIAT实体卡":"Get RIAT Physical Card",
        "RIAT创新跨境金融服务方案":"Revolutionize Your Finances with RIAT: A New Era of Cross-Border Solutions",
        "RIAT同时整合全球首款":"Embark on a world of opportunity with RIAT — the pioneer in integrated cross-border financial solutions. Our revolutionary platform is the first to offer a complete suite of products, including versatile currency exchange options, comprehensive global payment systems, and specialized corporate cards. We're committed to powering the globalization journey for businesses venturing beyond borders. Explore RIAT. Empower Your Global Ambition.",
        "全球收款账户立即解锁安全":"Unlock the power of global commerce effortlessly with RIAT Global payment accounts. Say goodbye to the hassle and hello to efficient financial management.",
        "无需如实体银行的繁琐手续和资料":"Simplified Onboarding: Forget the red tape of traditional banking. Set up your account in just 10 minutes and start transacting.",
        "低成本完成全球资金收付":"Cost-Effective Transactions: Make and receive global payments at competitive rates, reducing your expenses and fostering business growth.",
        "高效收付网络系统":"Same-Day Settlements: Benefit from our effective payment and collection network. Experience the convenience of same-day settlements.",
        "拥有多国支付牌照":"Global Trust: Operating with licenses across multiple countries and adhering to strict regulations, we ensure a secure and compliant financial environment.",
        "RIAT企业虚拟卡":"RIAT Business Virtual Card",
        "支持全球币种线上支持场景":"Streamline Your Global Payments with the RIAT Business Virtual Card",
        "一键完成开卡":"Instant Card Creation: Generate your card with a single click, ready for immediate use.",
        "无开卡数量限制":"Unlimited Card Issuance: Create as many cards as your business requires, tailored to meet diverse industry needs.",
        "支持全球币种线上场景":"Multi-Currency Transactions: Enjoy the freedom of making online payments in various global currencies.",
        "提供API接口":"Seamless API Integration: Enhance operational efficiency with our robust API, allowing for streamlined card management.",
        "支持全球币种线下交易":"Global Currency Transactions: Support for offline payments in various global currencies.",
        "支持全球ATM机器取现金提款":"Worldwide ATM Access: Withdraw cash from ATMs across the globe and say goodbye to cash constraints on your travels.",
        "实时账单推送消费一目了然":"Real-Time Notifications: Stay informed with instant alerts on payments, keeping your expenses transparent and manageable.",
        "超低费率":"Cost-Effective: Enjoy ultra-low fees and transparent exchange rates — the more you transact, the more you save.",
        "RIAT企业实体卡":"RIAT Physical Card",
        "用一张卡让商旅出行不在束缚":" Your Companion for Unhindered Business Travel ",
        "任何时候都可以全面管理你的卡片和资金":"Effortlessly manage your cards and funds at your convenience, anytime and anywhere, with our RIAT CARD APP.",
        "海外付款":"Instant Payment with Extensive Choice",
        "全景支持":"Choose from over 20 VISA and Mastercard BINs for immediate online payments, tailored to your business needs.",
        "一键开卡灵活使用":"One-Click Card Generation - Flexibility at Your Fingertips",
        "灵活的线上管理":"Flexible online management",
        "通过系统轻松实现卡片额度调整":"Unlimited card creation with easy adjustments for limits, expense tracking, and transaction checks through your personalized dashboard.",
        "BIN号丰富":"Abundant BIN Selection",
        "拥有全球多地区多国家VISA": "Choose from over 20+ BINs from VISA and Mastercard, facilitating global currency transactions with comprehensive verification support including VISA 3DS and VCC2.",
        "全场景支持":"Universal Online Applicability",
        "店租":"Ideal for e-commerce platforms (Amazon, Shopify, Shopee), cloud services, digital entertainment, app subscriptions, and expansive online shopping.",
        "适合多种跨境业":"Tailored for Diverse Cross-Border Operations",
        "让跨境企业海外付款更高效":"Boost efficiency in global business transactions across multiple domains",
        "跨境电商":"Cross-border E-commerce",
        "独立站运营费用":"Manage stand-alone site rentals, store fees, ad expenditures, domain purchases, server rentals, and logistics.",
        "海淘购物":"Global Shopping & Subscriptions",
        "解决海外网站购买":"Online purchases, gaming and app subscriptions, developer accounts, and global roaming fees are a breeze.",
        "海外广告":"Online Advertising",
        "轻松支付":"Effortlessly fund campaigns on Facebook, Google, YouTube, TikTok, Instagram, Twitter and more.",
        "其他海外业务":"Versatile Business Applications",
        "海外服务器租赁":"Book air tickets, hotels, pay for education, and other business-related expenses.",
        "全平台支持跨境无障碍":"All-Encompassing Merchant Compatibility",
        "轻松支付广告投放":"Handle ad management, store rentals, and web services with seamless payments across all merchants.",
        "完善的后台功能":"Advanced Backend Support, API Ready",
        "账号账单导出":"Account statements export",
        "卡片账单导出":"Card statements export",
        "余额手机":"Get balance notification via mobile/email",
        "实时交易风控":"Real-time transaction monitoring",
        "即刻体验瞬间付款的新形态":"Embrace the Future of Instant Payments",
        "更多卡BIN正在路上":"Your gateway to efficient, flexible, and instant global business payments starts with RIAT Business Virtual Cards. We're continuously adding more BINs to serve you better.",
        "立即体验":"Try Now",
        "请输入邮箱":"Please enter your email",
        "请输入正确的邮箱地址":"Please enter a valid email address!",
        "请输入姓名":"Please enter your name",
        "请输入电话区号":"Please enter country code",
        "请输入电话号码":"Please enter phone number",
        "请输入正确的电话号码":"Please enter a valid phone number!",
        "通过RIAT全球收款账户":"RIAT Global Account: Your Partner in Global Expansion",
        "让您的业务触达全球":"Global Reach, Simplified Collections",
        "支持包括美元":"Extend your business reach with support for collections in 15 key global currencies including USD, GBP, JPY, EUR, and HKD.",
        "低成本更高效":"Efficiency Meets Economy",
        "快速下户":"Rapid Setup",
        "无徐传统银行的繁琐手续和资料":" Skip the complex procedures of traditional banking; set up your global account in as little as 10 minutes.",//中文更正“无需”
        "同名收付":"Unified Collection and Payments",
        "提供美国":"Access same-name bank accounts in key financial hubs like the United States, Hong Kong, and Singapore for seamless payment and collection.",
        "比传统银行节约超过":"Experience a cost reduction of over 90% compared to conventional banks, with the added benefit of same-day settlements.",//中文T+后缺少文字
        "多币种多平台":" Multi-Currency Support Across Platforms",
        "多种主流币支持":"Diverse Currency Handling",
        "包括美元":"Transact with over 15 currencies including USD, GBP, JPY, CAD, HKD.",
        "支持绑定":"Connect with major online platforms such as Amazon, PayPal, and Klarna for effortless same-name payments and collections.",
        "高效率":"Cost-Effective Solutions",
        "支持将收单进行资金结算到RIAT账户":" Enjoy significantly lower costs and transfer collected funds seamlessly to your RIAT account.",
        "立刻创建您的RIAT账号全球收款账户":"Activate Your RIAT Global Collection Account Today",
        "用一个账户让您业务触达全球":"Empower your business to transcend borders with just one account. Set the stage for global success now.",
        "立即创建":"Sign Up Now",
        "立刻创建您的RIAT账号":"Activate your own RIAT account right now!",
        "感受创新科技金融带来的改变":"Discover the transformative impact of innovative fintech, revolutionizing your financial experience with simplicity and efficiency.",
        "收取交易":"collect a transaction fee",
        "查询余额":"Check balance",
        "实时充值":"real-time top-up",
        "语言":"Language",
        "已发送":"Sent",
       "我们会第一时间回复您":"We will get back to you as soon as possible"
    }
    
    